import React from 'react'
import { Layout } from 'antd'
import Pagewrapper from '../components/PageWrapper'

const TermsPage = () => (
    <Pagewrapper>
        <Layout>
            <div style={{ alignSelf: 'center', maxWidth: '600px', padding: '10px' }}>
                <h1>Terms of use</h1>
                <h2>1 Terms {'&'} conditions</h2>
                <p>
                    1.1 These subscription terms and conditions (“Terms {'&'} conditions”) are
                    accepted by answering "yes" on the pop-up when signing up on our website.
                </p>

                <h3>2. SUBSCRIPTION TERMS AND INVOICING</h3>
                <p>
                    2.1 The subscription becomes effective upon placement of order and continues to
                    be in effect until terminated in accordance with these Terms and Conditions.
                </p>

                <p>
                    2.2 The subscription period is 6 months. First period being 6 months from the
                    order date. Subsequent periods are also 6 months. Exceptions to the described
                    periods have to be set out in separate agreement or terms for the specific
                    product.
                </p>

                <p>
                    2.3 After placement of order, new customers are entitled to cancel the order
                    free of charge within the period of the trial period listed on the website.
                </p>

                <h3>3. SCOPE AND USE OF SUBSCRIPTION</h3>

                <p>
                    3.1 In accordance with these Terms and Conditions, the Customer is granted a
                    non-exclusive limited right to use the ll33a.dk program and selected additional
                    modules (collectively the “Application”) which are made available online as
                    software as a service only for the purposes described by the Application. The
                    Customer does not acquire the Application or any copy or part and is not granted
                    a licence to implement the Application in any way other than as a software as a
                    service.
                </p>

                <p>
                    3.2 The Customer’s subscription entitles the Customer to use the Application for
                    the usage specified for each product. If the Customer needs further capacity or
                    functionality, then upon use of any additional entries, users or modules by the
                    Customer or upon any acceptance by ll33a.dk of any requested increase to
                    entries, users or modules, the subscription will be upgraded automatically and
                    the Customer shall agree to pay the consequential increase in the subscription
                    at the then current rates for such entries, users and modules.
                </p>

                <p>
                    3.3 Only the Customer and its advisors are entitled to use the Application, and
                    the Application may not be used for or on behalf of any other parties or for
                    data processing or the provision of services for other parties than the
                    Customer. The Customer agrees to be fully responsible and liable for any third
                    parties that are given access to the Application by the Customer or who use the
                    Customer’s log in details.
                </p>

                <p>
                    3.4 Other than as set out in 3.3, the Customer is not entitled to assign the
                    subscription or grant access to the Application, whether in full or in part, to
                    any third party.
                </p>

                <p>
                    3.5 The Customer shall ensure that the Application is not used in any manner
                    which reflects adversely upon the name, reputation and/or goodwill of ll33a.dk
                    or in breach of any applicable law or regulation.
                </p>

                <h3>4. PRICES AND TERMS OF PAYMENT</h3>

                <p>4.1 The terms of payment are paid by the start of each subscription period.</p>

                <p>
                    4.2 If the subscription fee is not paid when due, access to ll33a.dk may be
                    barred.
                </p>

                <p>
                    4.3 The Customer accepts that invoices and reminders sent by email to the email
                    address provided by the Customer shall be deemed delivered when sent by
                    ll33a.dk.
                </p>

                <p>
                    4.4 The prices, rates and subscription types in force from time to time can be
                    found on ll33a.dk's website and may be changed at the end of each calendar
                    quarter on one month’s notice.
                </p>

                <h3>5. TERMINATION</h3>

                <p>
                    5.1 By using the Application, the Customer may terminate the subscription,
                    reduce the services subscribed to and remove additional modules each with effect
                    from the last day of the current subscription period (unless otherwise stated in
                    the description or terms and conditions applying to the specific service or
                    module).
                </p>

                <p>
                    5.2 ll33a.dk is entitled to terminate the subscription at the end of any
                    calendar quarter by giving more than 3 month’s notice or immediately on written
                    notice if the Customer commits a material breach of these Terms and Conditions
                    or becomes insolvent or has a receiver or administrator appointed over its
                    assets.
                </p>

                <h3>6. CUSTOMER DATA</h3>

                <p>
                    6.1 As between the parties, the Customer shall own any and all data it provides
                    to ll33a.dk or the Application. The Application permits the Customer to export
                    records and data held by the Application and the Customer agrees to export any
                    and all data prior to termination of the subscription.
                </p>

                <p>
                    6.2 ll33a.dk reserves the right to delete Customer data 90 days after
                    termination of the subscription regardless of the reason for termination, and
                    ll33a.dk is not obligated to store any Customer data after such time.
                </p>

                <p>
                    6.3 ll33a.dk shall be entitled to store Customer data after termination in
                    anonymised form for statistical and analytical purposes only.
                </p>

                <p>
                    6.4 ll33a.dk may disclose Customer data to third parties and public authorities
                    where such disclosure in ll33a.dk’s opinion is justifiable and reasonable, e.g.
                    to avoid a loss of value, including in connection with judgments, public
                    authority orders, the Customer’s bankruptcy, death or the like.
                </p>

                <h3>7. OPERATIONAL STABILITY AND CHANGE</h3>

                <p>
                    7.1 ll33a.dk strives towards the highest possible operational stability on this
                    service, but shall not be responsible or liable for any breakdowns or service
                    interruptions, including interruptions caused by factors beyond ll33a.dk’s
                    control, such as power failures, defective equipment, Internet connections,
                    telecoms connections or the like.
                </p>

                <p>
                    7.2 The Application and the service is provided “as is” and ll33a.dk expressly
                    disclaims any further representations, warranties, conditions or other terms,
                    express or implied, by statute, collaterally or otherwise, including but not
                    limited to implied warranties, conditions or other terms of satisfactory
                    quality, fitness for a particular purpose or reasonable care and skill.
                </p>

                <p>
                    7.3 In the event of an interruption of service ll33a.dk will use reasonable
                    commercial endeavour’s to restore normal operations as soon as possible.
                </p>

                <h3>8. INTELLECTUAL PROPERTY RIGHTS</h3>

                <p>
                    8.1 The Application and any information provided by it, other than the
                    Customer’s data, is protected by copyright and other intellectual property
                    rights and is owned by or licensed to ll33a.dk. Any development or adaptations
                    made to such intellectual property by Customer shall vest in ll33a.dk. The
                    Customer shall notify ll33a.dk of any actual or suspected infringement of
                    ll33a.dk’s intellectual property rights and any unauthorised use of the
                    Application that the Customer is aware of.
                </p>

                <p>8.2 No intellectual property rights are assigned to the Customer.</p>

                <p>
                    8.3 In relation to any and all material uploaded by the Customer and any and all
                    Customer data, the Customer grants to ll33a.dk, its suppliers and
                    sub-contractors a non-exclusive worldwide irrevocable licence to provide the
                    Application and related services including marketing services to the Customer.
                    The Customer represents and warrants that no uploaded material or Customer data
                    will infringe third party rights or intellectual property rights and will not
                    contain any material that is obscene, offensive, inappropriate or in breach of
                    any applicable law.
                </p>

                <h3>9. ASSIGNMENT</h3>

                <p>
                    9.1 ll33a.dk is entitled to assign its rights and obligations regarding the
                    Customer to a group company or to a third party.
                </p>

                <p>
                    9.2 The Customer accepts that ll33a.dk is entitled to use subcontractors in all
                    matters, including for the implementation and operation of the Application and
                    the storage of Customer data.
                </p>

                <h3>10. ll33a.dk’s LIABILITY</h3>

                <p>
                    10.1 ll33a.dk disclaims all liability and shall not be liable in contract, tort
                    (including negligence), statutory duty, pre-contract or otherwise arising out of
                    or in connection with these Terms and Conditions or the Application for any (a)
                    consequential, indirect or special loss or damage; or (b) any loss of goodwill,
                    reputation or data; or (c) any economic losses (including loss of revenues,
                    profits, contracts, business or anticipated savings). In each case whether
                    advised of the possibility of such loss or damage and howsoever incurred.
                </p>

                <p>
                    10.2 ll33a.dk is not liable for third party solutions which are available via
                    and/or integrated with the Application, including currency feeds/currency
                    calculators. Consequently, ll33a.dk cannot be held liable for the correctness,
                    completeness, quality and reliability of the information or for the results
                    which are achieved by means of such third party solutions. Moreover, ll33a.dk
                    cannot be held liable for the availability, security or functionality of such
                    third party solutions, including for any damage and/or loss caused by such third
                    party solutions. The Customer is responsible for proving that a loss or damage
                    suffered by the Customer is not attributable to any third party solutions.
                </p>

                <p>
                    10.3 The maximum liability of ll33a.dk in contract, tort (including negligence),
                    statutory duty, or otherwise arising out of or in connection with the Terms and
                    Conditions or the Application; shall, in respect of any one or more events or
                    series of events (whether connected or unconnected) taking place within any
                    twelve month period, be limited to the Fees paid by Customer in such period or
                    DKK 500, whichever is the greater.
                </p>

                <p>
                    10.4 The Customer undertakes to indemnify ll33a.dk against any costs due to
                    product liability loss, third party loss or other third party claims due to the
                    Customer’s use of the Application.
                </p>

                <p>
                    10.5 Nothing in this Agreement shall exclude or limit liability for death or
                    personal injury or for fraud.
                </p>

                <h3>11. DUTY OF CONFIDENTIALITY AND DATA SECURITY</h3>

                <p>
                    11.1 ll33a.dk will only process Customer data in accordance with the Customer’s
                    instructions and not for its own, unauthorised purposes.
                </p>

                <p>
                    11.2 ll33a.dk will keep confidential all of the Customer’s confidential
                    information that the Customer provides to ll33a.dk save to where such
                    information has come into the public domain other than by breach of this clause,
                    or where ll33a.dk has obtained the information from a third party without a duty
                    of confidence or where it is required to be disclosed by a regulatory or
                    government body or court of competent jurisdiction.
                </p>

                <p>
                    11.3 ll33a.dk shall take all necessary technical and organisational security
                    measures to ensure the safe and secure processing of any Customer data. ll33a.dk
                    shall comply with its obligations under the Data protection policy in accordance
                    with the EU General Data Protection Regulation (GDPR) and Data Protection Act
                    (DPA) 2018.
                </p>

                <p>
                    11.4 At the Customer’s request ll33a.dk will provide sufficient information to
                    enable the Customer to ensure that the said technical and organisational
                    measures have been taken. ll33a.dk shall be permitted to charge the Customer for
                    such work at its standard rates.
                </p>

                <p>
                    11.5 Where the Customer provides information, user names or passwords in
                    relation to any third party information feed or service to ll33a.dk, the
                    Customer shall warrant that the provision of such information or the integration
                    of the Application with such third party feed or service or the storage and use
                    by ll33a.dk of such information shall not breach the terms and conditions for
                    such service or any other third party rights. The Customer shall indemnify and
                    hold harmless ll33a.dk from any and all loss, damage, cost and expense arising
                    from breach of this clause.
                </p>

                <h3>12. CHANGE OF TERMS AND CONDITIONS</h3>

                <p>
                    12.1 ll33a.dk may update these Terms and Conditions from time to time. The
                    current version of the Terms and Conditions in force from time to time are
                    available on ll33a.dk’s website. ll33a.dk shall endeavour to provide reasonable
                    notice of any changes by posting such changes on the web site.
                </p>

                <p>
                    12.2 Further use of the Application after any change to the Terms and Conditions
                    shall be deemed to be acceptance of such Terms and Conditions. It is the
                    Customer’s responsibility to check the website regularly to keep updated on any
                    changes to these Terms and Conditions.
                </p>

                <h3>13. DISPUTES</h3>

                <p>
                    13.1 These terms and conditions shall be construed in accordance with the laws
                    of Denmark and each party hereby irrevocably submits to the non-exclusive
                    jurisdiction of the courts of Denmark.
                </p>

                <h3>14. Validity</h3>

                <p>
                    14.1 Validity: These Terms and Conditions become effective on January 13th 2021,
                    and supersede all previous terms and conditions.
                </p>

                <p>
                    Please let us know if you have any questions about these terms at info@ll33a.dk.
                </p>
            </div>
        </Layout>
    </Pagewrapper>
)

export default TermsPage
